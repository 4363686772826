import React, {useCallback, useEffect, useState} from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
  Box,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import {Euro as EuroIcon, LocalOffer as LocalOfferIcon} from "@mui/icons-material";
import slugify from "slugify";

import {importAll} from "../Utils";
import {FormLabelType} from "../Types";
import {useDebounce, useSupabase} from "../Hooks";

export const LabelForm = ({ data, isFirst, addNewLabel, onChangeLabel, onDeleteLabel } : {data: FormLabelType, isFirst: boolean, addNewLabel: any, onChangeLabel: any, onDeleteLabel: any}) => {
  const [label, setLabel] = useState<FormLabelType>(data);
  const debouncedValues = useDebounce(label, 500);
  const [productDb, productDbLoading, productDbError] = useSupabase(debouncedValues.ean);

  // Form handles
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLabel({
        ...label,
        [event.target.name]: event.target.value,
      });
    },
    [label]
  );

  const handleSelectChange = useCallback(
    (event: SelectChangeEvent) => {
      setLabel({
        ...label,
        [event.target.name]: event.target.value,
      });
    },
    [label]
  );

  // EAN search
  useEffect(() => {
    if(productDb) {
      setLabel({
        ...label,
        name: productDb.name ?? '',
        capacity: `${productDb.quantity && productDb.quantity > 0 ? productDb.quantity : ''}${productDb.unit ?? ''}`,
      })
    }
  }, [productDb]);

  // Callback to parent
  useEffect(() => {
    onChangeLabel(debouncedValues);
  }, [debouncedValues]);

  // Utils variables for rendering
  const icons = importAll(require.context('../Icons', false, /\.(svg)$/));
  const helperText = productDbError ? productDbError : productDbLoading ? 'Recherche en cours...' : productDb ? 'Produit trouvé et importé' : ''

  return (
    <>
      <Box component="form" sx={{mt: 1}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              margin="normal"
              id="ean"
              {...(!!productDb || !!productDbError) && {focused: true}}
              label="Code EAN"
              name="ean"
              value={label.ean}
              onChange={handleInputChange}
              color={productDb ? 'success' : productDbError ? 'error' : 'primary'}
              error={!!productDbError}
              helperText={helperText}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              margin="normal"
              id="name"
              label="Nom du produit"
              name="name"
              value={label.name}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              margin="normal"
              id="capacity"
              label="Contenance du produit"
              name="capacity"
              value={label.capacity}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{mt: 2}}>
                <InputLabel id="icon-label" size="small">
                  Icon
                </InputLabel>
                <Select
                  size="small"
                  labelId="icon-label"
                  id="icon"
                  name="icon"
                  value={label.icon}
                  label="Icon"
                  onChange={handleSelectChange}
                >
                  <MenuItem value="text" key="text">
                    Renseigner un texte
                  </MenuItem>
                  {icons.map(function (image: any, index: number) {
                    const fileName = image.split('/').pop().split('.').shift();
                    const componentName = fileName.charAt(0).toUpperCase() + fileName.slice(1);

                    return (
                      <MenuItem value={componentName} key={index}>
                        <img alt={fileName} src={image} width="50"/>
                        {fileName.replace(/[^a-zA-Z]/g, ' ')}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                size="small"
                margin="normal"
                id="discount"
                label="Promotion"
                name="discount"
                multiline={true}
                value={label.discount}
                onChange={handleInputChange}
                disabled={label.icon !== 'text'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalOfferIcon fontSize="small"/>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                size="small"
                margin="normal"
                id="oldPrice"
                label="Prix barré"
                name="oldPrice"
                type="number"
                value={label.oldPrice}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EuroIcon fontSize="small"/>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                size="small"
                margin="normal"
                id="price"
                label="Prix"
                name="price"
                type="number"
                value={label.price}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EuroIcon fontSize="small"/>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                size="small"
                margin="normal"
                id="discountDetail"
                label="Détail promotion"
                name="discountDetail"
                value={label.discountDetail}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid item xs={6}>
            {isFirst ? null : (
              <Button
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
                onClick={onDeleteLabel}
                color={"error"}
              >
                Supprimer l'étiquette
              </Button>
            )}
          </Grid>

          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2}}
              onClick={addNewLabel}
              color={"success"}
            >
              Ajouter une étiquette
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default LabelForm;
