import React, {useCallback} from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
  Switch,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

import {FormPaperColorEnum} from "../Types";

export const PrintOptionsForm = ({ options, onChange } : any) => {
  // Form handles
  const handleSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...options,
        [event.target.name]: event.target.checked,
      });
    },
    [options]
  );

  const handleSelectChange = useCallback(
    (event: SelectChangeEvent) => {
      onChange({
        ...options,
        [event.target.name]: event.target.value,
      });
    },
    [options]
  );

  return (
    <>
      <h2>Configuration impression</h2>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="size-label" size={"small"}>
              Format des étiquettes
            </InputLabel>
            <Select
              size="small"
              labelId="size-label"
              id="size"
              name="size"
              value={options.size as string}
              label="Format des étiquettes"
              onChange={handleSelectChange}
            >
              <MenuItem value="A4">A4</MenuItem>
              <MenuItem value="A5">A5</MenuItem>
              <MenuItem value="A6">A6</MenuItem>
              <MenuItem value="A7">A7</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={8} sm={4}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                name="manageColor"
                onChange={handleSwitchChange}
                defaultChecked={!!options.manageColor}
              />
            }
            label="Impression couleur"
            labelPlacement="start"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl>
            <FormLabel id="paper-label">Papier</FormLabel>
            <RadioGroup
              row
              aria-labelledby="paperColor-label"
              name="paperColor"
              value={options.paperColor}
              onChange={handleSelectChange}
            >
              <FormControlLabel
                value={FormPaperColorEnum.WHITE}
                control={<Radio/>}
                label="Blanc"
              />
              <FormControlLabel
                value={FormPaperColorEnum.YELLOW}
                control={<Radio/>}
                label="Jaune"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default PrintOptionsForm;
