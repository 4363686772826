import {useEffect, useState} from "react";

import {Tables} from "../Types";
import {isEan, supabase} from "../Utils";

export const useSupabase = (ean: string): [productDb: Tables<'products'>|null, productDbLoading: boolean, productDbError: string|null] => {
  const [productDb, setProductDb] = useState<Tables<'products'>|null>(null)
  const [productDbLoading, setProductDbLoading] = useState<boolean>(false)
  const [productDbError, setProductDbError] = useState<string|null>(null)

  useEffect(() => {
    if (!ean) {
      setProductDbError(null)
      return
    }

    if (!isEan(ean)) {
      setProductDbError('Code EAN invalide.')
      return
    }

    async function getProductDb() {
      setProductDbError(null)
      setProductDbLoading(true)
      const { data, error } = await supabase.from('products')
        .select().eq('ean', ean).order('id', { ascending: false }).limit(1)

      if (error) {
        setProductDbError(error.message)
        setProductDbLoading(false)
        return
      }

      if (!data || data.length === 0) {
        setProductDbError('Aucun produit trouvé avec ce code EAN.')
        setProductDb(null)
        setProductDbLoading(false)
        return
      }

      setProductDb(data[0])
      setProductDbLoading(false)
    }

    getProductDb()

    return () => {
      setProductDb(null)
      setProductDbError(null)
      setProductDbLoading(false)
    }
  }, [ean]);


  return [productDb, productDbLoading, productDbError]
};

export default useSupabase;
