import React from "react";
import {
  Page,
  Text,
  View,
  Document as PdfDocument,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import {PageSize} from "@react-pdf/types";

import {FormLabelType, FormPaperColorEnum, FormPrintOptionsType} from "../Types";
{/* @ts-ignore */}
import BebasNeueRegular from "../Fonts/BebasNeue-Regular.ttf";
{/* @ts-ignore */}
import ArimoItalic from "../Fonts/Arimo-Italic.ttf";

import * as Icons from "../Icons/Components";

Font.register({ family: 'BebasNeueRegular', src: BebasNeueRegular });
Font.register({ family: 'ArimoItalic', src: ArimoItalic, fontStyle: 'italic' });


export const Document = ({printOptions, labels}: { printOptions: FormPrintOptionsType, labels: FormLabelType[] }) => {
  const pageBackgroundColor = printOptions.paperColor === FormPaperColorEnum.WHITE ? "white" : "yellow"
  const discountBackgroundColor = printOptions.manageColor ? "red" : "black"
  const discountColor = printOptions.paperColor === FormPaperColorEnum.WHITE ? "white" : "yellow"
  const priceColor = printOptions.manageColor ? "red" : "black"
  const logo = printOptions.manageColor ? "logo-supra-couleur.png" : "logo-supra-nb.png"

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      flexWrap: "wrap",
      backgroundColor: pageBackgroundColor,
      margin: 0,
      padding: 0,
    },
    border: {
      backgroundColor: "black",
      margin: 0,
      padding: 0,
    },
    label: {
      backgroundColor: pageBackgroundColor,
    },
    header: {
    },
    name: {
      fontFamily: 'BebasNeueRegular',
      maxLines: 1,
    },
    capacity: {
      fontFamily: 'ArimoItalic',
      fontStyle: "italic",
      fontWeight: "normal",
      maxLines: 1,
    },
    discountBox: {
      flexDirection: 'row',
      borderColor: discountBackgroundColor,
      backgroundColor: discountBackgroundColor,
      fontFamily: 'BebasNeueRegular',
    },
    discount: {
      justifyContent: 'center',
      alignItems: 'center',
      color: discountColor,
      textAlign: 'center',
    },
    priceBox: {
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: pageBackgroundColor,
      flexGrow: 1,
    },
    oldPrice: {
      textDecoration: 'line-through',
      opacity: 0.5,
    },
    price: {
      color: priceColor,
    },
    discountDetail: {},
    companyLogo: {
      alignSelf: 'flex-end',
    },
  });

  const labelMarginA4 = 0.6;
  const labelMarginA5 = 0.4;
  const labelMarginA6 = 0.25;
  const labelMarginA7 = 0.18;

  const themeLabelBySize = {
    A4: {
      width: 29.7 - (labelMarginA4 * 2) + "cm",
      height: 21 - (labelMarginA4 * 2) + "cm",
      margin: labelMarginA4 + "cm",
      padding:15,
    },
    A5: {
      width: 21 - (labelMarginA5 * 2) + "cm",
      height: 14.8 - (labelMarginA5 * 2) + "cm",
      margin: labelMarginA5 + "cm",
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 6,
      paddingBottom: 6,
    },
    A6: {
      width: 14.8 - (labelMarginA6 * 2) + "cm",
      height: 10.5 - (labelMarginA6 * 2) + "cm",
      margin: labelMarginA6 + "cm",
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 5,
      paddingBottom: 5,
    },
    A7: {
      width: 10.5 - (labelMarginA7 * 2) + "cm",
      height: 7.4 - (labelMarginA7 * 2) + "cm",
      margin: labelMarginA7 + "cm",
      paddingLeft: 7,
      paddingRight: 7,
      paddingTop: 3,
      paddingBottom: 3,
    },
  };

  const themeNameBySize = {
    A4: {
      fontSize: 65,
      lineHeight: "65px",
    },
    A5: {
      fontSize: 45,
      lineHeight: "45px",
    },
    A6: {
      fontSize: 30,
      lineHeight: "30px",
    },
    A7: {
      fontSize: 22,
      lineHeight: "22px",
    },
  };

  const themeCapacityBySize = {
    A4: {
      fontSize: 25,
      marginTop: 2,
    },
    A5: {
      fontSize: 16,
      marginTop: 6,
    },
    A6: {
      fontSize: 12,
      marginTop: 4,
    },
    A7: {
      fontSize: 8,
      marginTop: 2,
    },
  };

  const themeDiscountBoxBySize = {
    A4: {
      marginTop: 40,
      borderWidth: 8,
    },
    A5: {
      marginTop: 30,
      borderWidth: 6,
    },
    A6: {
      marginTop: 20,
      borderWidth: 4,
    },
    A7: {
      marginTop: 15,
      borderWidth: 3,
    },
  };

  const themeDiscountBySize = {
    A4: {
      width: 245,
      height: 245,
      fontSize: 96,
      lineHeight: "96px",
    },
    A5: {
      width: 175,
      height: 175,
      fontSize: 66,
      lineHeight: "66px",
    },
    A6: {
      width: 125,
      height: 125,
      fontSize: 51,
      lineHeight: "51px",
    },
    A7: {
      width: 90,
      height: 90,
      fontSize: 35,
      lineHeight: "35px",
    },
  };

  const themeOldPriceBySize = {
    A4: {
      fontSize: 36,
      lineHeight: "16px",
    },
    A5: {
      fontSize: 26,
      lineHeight: "11px",
    },
    A6: {
      fontSize: 18,
      lineHeight: "8px",
    },
    A7: {
      fontSize: 12,
      lineHeight: "5px",
    },
  };

  const themePriceBySize = {
    A4: {
      fontSize: 155,
      lineHeight: "145px",
    },
    A5: {
      fontSize: 105,
      lineHeight: "100px",
    },
    A6: {
      fontSize: 75,
      lineHeight: "72px",
    },
    A7: {
      fontSize: 55,
      lineHeight: "53px",
    },
  };

  const themeDiscountDetailBySize = {
    A4: {
      fontSize: 36,
      lineHeight: "36px",
    },
    A5: {
      fontSize: 26,
      lineHeight: "26px",
    },
    A6: {
      fontSize: 18,
      lineHeight: "16px",
    },
    A7: {
      fontSize: 12,
      lineHeight: "12px",
    },
  };

  const themeCompanyLogoBySize = {
    A4: {
      height: 70,
      marginTop: 35,
    },
    A5: {
      height: 50,
      marginTop: 25,
    },
    A6: {
      height: 35,
      marginTop: 20,
    },
    A7: {
      height: 25,
      marginTop: 13,
    },
  };

  const getPageOrientation = (size: PageSize) => {
    switch (size) {
      case "A4":
        return "landscape";
      case "A5":
        return "portrait";
      case "A6":
        return "landscape";
      case "A7":
        return "portrait";
      default:
        return "landscape";
    }
  }

  // @ts-ignore
  return (
    <PdfDocument>
      <Page
        orientation={getPageOrientation(printOptions.size)}
        size={"A4"}
        style={{...styles.page}}
      >
        {labels.map((label) => {
          // @ts-ignore
          const Icon = Icons[label.icon.replace(/[^a-zA-Z]/g, '')] || null;

          return (
            // @ts-ignore
            <View key={label.id} style={{...styles.border}}>
              {/* @ts-ignore */}
              <View style={{...styles.label, ...themeLabelBySize[printOptions.size]}}>
                {/* @ts-ignore */}
                <View style={{...styles.header}}>
                    {/* @ts-ignore */}
                    <Text style={{...styles.name, ...themeNameBySize[printOptions.size]}}>
                      {label.name.length > 0 ? label.name : " "}
                    </Text>
                    {/* @ts-ignore */}
                    <Text style={{...styles.capacity, ...themeCapacityBySize[printOptions.size]}}>
                      {label.capacity.length > 0 ? label.capacity : " "}
                    </Text>
                </View>

                {/* Section de réduction */}
                {/* @ts-ignore */}
                <View style={{...styles.discountBox, ...themeDiscountBoxBySize[printOptions.size]}}>
                  {/* @ts-ignore */}
                  <View style={{...styles.discount, ...themeDiscountBySize[printOptions.size]}}>
                    {Icon ? <Icon color={discountColor} /> : label.icon ? <Text>{label.discount}</Text> : null}
                  </View>
                  <View style={styles.priceBox}>
                    {/* @ts-ignore */}
                    <Text style={{...styles.oldPrice, ...themeOldPriceBySize[printOptions.size]}}>{label.oldPrice && label.oldPrice + '€'}</Text>
                    {/* @ts-ignore */}
                    <Text style={{...styles.price, ...themePriceBySize[printOptions.size]}}>{label.price && label.price + '€'}</Text>
                    {/* @ts-ignore */}
                    <Text style={{...styles.discountDetail, ...themeDiscountDetailBySize[printOptions.size]}}>{label.discountDetail}</Text>
                  </View>
                </View>

                {/* @ts-ignore */}
                <Image src={logo} style={{...styles.companyLogo, ...themeCompanyLogoBySize[printOptions.size]}}/>
              </View>
            </View>
          )
        })}
      </Page>
    </PdfDocument>
  );
};

export default Document;
