import React, {useEffect, useRef, useState} from "react";
import {Box, Button, Grid, Tab,} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {PDFDownloadLink, PDFViewer} from "@react-pdf/renderer";
import slugify from "slugify";

import {Document, LabelForm, PrintOptionsForm, Waves} from "./Components";
import {FormLabelType, FormPaperColorEnum, FormPrintOptionsType} from "./Types";
import {useDebounce, useWindowSize} from "./Hooks";

import "./App.scss";

function App() {
  // Data
  const [windowWidth] = useWindowSize();
  const iframeRef = useRef<any>();
  const debouncedWindowWidth = useDebounce(windowWidth, 500);

  const [printOptions, setPrintOptions] = useState<FormPrintOptionsType>({
    size: 'A4',
    manageColor: true,
    paperColor: FormPaperColorEnum.WHITE
  });
  const [labels, setLabels] = useState<FormLabelType[]>([]);
  const [currentTab, setCurrentTab] = React.useState<string>('');

  // Form handles
  const handlePrintOptionsChange = (newOptions: FormPrintOptionsType) => {
    setPrintOptions(newOptions);
  };

  const handleLabelChange = (id: number, newLabel: FormLabelType) => {
    const updatedLabels = labels.map((label) =>
      label.id === id ? newLabel : label
    );
    setLabels(updatedLabels);
  };

  const handleLabelDelete = (id: number) => {
    const previousTab = [...labels].reverse().find((label) => label.id < id);

    const updatedLabels = labels.filter((label) => label.id !== id);
    setLabels(updatedLabels);

    setCurrentTab(previousTab ? previousTab.id.toString() : '');
  }

  const addNewLabel = ()  => {
    const id = Date.now();
    setLabels([...labels, {
      id,
      ean: "",
      name: "COTOPHARM® BIO COTON",
      capacity: "180 pads x3",
      discount: "-3€",
      discountDetail: "Jusqu’au 30/12/2024",
      price: "6.99",
      oldPrice: "9.99",
      icon: "text"
    }]);
    setCurrentTab(id.toString());
  };

  const handleChangeCurrentTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue.toString());
  };

  useEffect(() => {
    if (labels.length === 0) {
      addNewLabel();
    }
  }, []);

  useEffect(() => {
    if (currentTab === '' && labels.length > 0) {
      setCurrentTab(labels[0].id.toString());
    }
  }, [currentTab]);

  useEffect(() => {
    if (iframeRef.current) {
      const ratio: { [key: string]: number } = {
        A4: 1.41428571429,
        A5: 0.70707070707,
        A6: 1.41428571429,
        A7: 0.70707070707,
      };

      const height = Math.ceil(
        (iframeRef.current.offsetWidth - 40) /
        ratio[printOptions.size as string]
      );
      const iframe = iframeRef.current.querySelectorAll("iframe");
      iframe[0].style.height = height + "px";

    }
  }, [debouncedWindowWidth, printOptions.size]);

  return (
    <>
      <div className="header">
        <Grid
          spacing={4}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item md={6} xs={12}>
            <div className="card">
              <div>
                <PrintOptionsForm options={printOptions} onChange={handlePrintOptionsChange}/>

                <h2>Étiquettes</h2>

                <TabContext value={currentTab}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeCurrentTab} variant="scrollable" scrollButtons="auto">
                      { labels.map(
                        (label: FormLabelType) => <Tab key={label.id} value={label.id.toString()} label={label.name || 'Étiquette'} />
                      ) }
                    </TabList>
                  </Box>
                  { labels.map(
                    function (label: FormLabelType) {
                      return (
                        <TabPanel key={label.id} value={label.id.toString()}>
                          <LabelForm
                            data={label}
                            isFirst={labels[0].id === label.id}
                            addNewLabel={addNewLabel}
                            onChangeLabel={(label: FormLabelType) => handleLabelChange(label.id, label)}
                            onDeleteLabel={() => handleLabelDelete(label.id)}
                          />
                        </TabPanel>
                      );
                    }
                  ) }
                </TabContext>

                <Grid item xs={12}>
                  <PDFDownloadLink
                    fileName={slugify('affichette', {
                      replacement: "-",
                      lower: true,
                    })}
                    document={
                      <Document printOptions={{...printOptions, paperColor: FormPaperColorEnum.WHITE}} labels={labels} />
                    }
                    style={{textDecoration: "none"}}
                  >
                    {/* @ts-ignore */}
                    {() => {
                      return (
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{mt: 3, mb: 2}}
                        >
                          Télécharger
                        </Button>
                      );
                    }}
                  </PDFDownloadLink>
                </Grid>
              </div>
            </div>
          </Grid>

          <Grid item md={4} xs={12}>
            <div className="card" ref={iframeRef}>
              <h1>Prévisionnel</h1>

              <PDFViewer showToolbar={false} width="100%">
                <Document printOptions={printOptions} labels={labels} />
              </PDFViewer>
            </div>
          </Grid>
        </Grid>

        <Waves/>
      </div>

      <div className="footer flex">
        <p></p>
      </div>
    </>
  );
}

export default App;
